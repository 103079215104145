import { translateItem } from "./use-translations"
const initialized: Ref<boolean> = ref(false)

const categories: Ref<Array<Category>> = ref([])

const categoriesItems = computed(() =>
  categories.value.map((r) => {
    return {
      label: translateItem(r)?.name,
      value: r.id
    }
  })
)



export const useProgramCategories = () => {
  const config = useRuntimeConfig()

  const fetchData = async () => {
    if (initialized.value) {
      return
    }

    /**
     * fetch categories
     */
    const response = await $fetch<Category>(config.public.apiBaseURL + '/items/category_boa', {
      query: {
        fields: 'id,translations.*'
      }
    })

    categories.value = response.data || []

    initialized.value = true
  }

  return {
    fetchData,
    categories,
    categoriesItems
  }
}
